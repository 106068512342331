// orientation-lock.service.ts

import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { isLargeScreen as isLargeScreenDevice } from '../../../global.variable';

@Injectable({
  providedIn: 'root',
})
export class OrientationLockService {
  isLargeScreen = new Subject();

  largeView: Boolean = isLargeScreenDevice;

  lockOrientation(): void {
    window.addEventListener('orientationchange', () => {
      if (!this.largeView && window.screen && window.screen.orientation) {
        if (
          window.screen.orientation.type === 'landscape-primary' ||
          window.screen.orientation.type === 'landscape-secondary'
        ) {
          // If in landscape mode, enter fullscreen mode
          setTimeout(() => {
            this.enterFullscreenMode();
          }, 1000);
        }
      }
    });
  }

  private enterFullscreenMode(): void {
    const documentElement = document.documentElement as any;
    if (documentElement.requestFullscreen) {
      documentElement
        .requestFullscreen()
        .then(() => {
          this.lockScreenOrientation();
        })
        .catch((error: any) => {
          throw error;
        });
    } else {
      this.exitFullscreenMode();
    }
  }

  private lockScreenOrientation(): void {
    const screenOrientation = screen.orientation as any;
    if (window.screen && window.screen.orientation && screenOrientation.lock) {
      screenOrientation
        .lock('portrait')
        .catch((error: any) => {
          throw error;
        })
        .finally(() => {});
    }
  }

  private exitFullscreenMode(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen().catch((error: any) => {
        throw error;
      });
    }
  }
}
