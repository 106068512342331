import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { DeviceInfoService } from '../../../shared/services/device-info.service';

@Injectable({
  providedIn: 'root',
})
export class RosterScheduleService {
  constructor(
    private http: HttpClient,
    private deviceInfoService: DeviceInfoService
  ) {}

  // static job type APIs
  jobSchdeules(body?: any, params?: any) {
    return this.http
      .post(`/api/job/job_schedules/`, body, { params: params })
      .pipe(map((response: any) => response));
  }
  // roster APIs
  rosterInfo(body?: any, params?: any) {
    return this.http
      .post(`/api/job/roster/`, body, { params: params })
      .pipe(map((response: any) => response));
  }
  saveShift(body?: any, params?: any) {
    return this.http
      .post(`/api/roster_schedule/`, body, { params: params })
      .pipe(map((response: any) => response));
  }
  getRosterSchedules(params?: any) {
    return this.http
      .get(`/api/roster_schedule/schedule_roster/`, { params: params })
      .pipe(map((response: any) => response));
  }
  async updateRosterSchedule(id: string, body?: any, params?: any) {
    body = {
      ...body,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .put(`/api/roster_schedule/${id}/`, body, { params: params })
        .pipe(map((response: any) => response))
    );
  }
  async createRosterScheduleJob(body?: any, params?: any) {
    body = {
      ...body,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`/api/roster_schedule/add_job/`, body, { params: params })
        .pipe(map((response: any) => response))
    );
  }
  listRosterSchedules(params?: any) {
    return this.http
      .get(`/api/roster_schedule/`, { params: params })
      .pipe(map((response: any) => response));
  }
  getRosterScheduleDetail(id: string, params?: any) {
    return this.http
      .get(`/api/roster_schedule/${id}/`, { params: params })
      .pipe(map((response: any) => response));
  }
  addPatrolRoute(body: any, params?: any) {
    return this.http
      .post(`/api/roster_schedule/add_patrol_route/`, body, { params: params })
      .pipe(map((response: any) => response));
  }
  async managePatrolRoute(body: any, params?: any) {
    body = {
      ...body,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`/api/roster_schedule/manage_patrol_route/`, body, {
          params: params,
        })
        .pipe(map((response: any) => response))
    );
  }
  getPatrolRoute(body: any, params?: any) {
    return this.http
      .post(`/api/roster_schedule/get_patrol_route_detail/`, body, {
        params: params,
      })
      .pipe(map((response: any) => response));
  }
  async createRosterSchedulePatrol(body?: any, params?: any) {
    body = {
      ...body,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`/api/roster_schedule/get_or_create_patrol_route/`, body, {
          params: params,
        })
        .pipe(map((response: any) => response))
    );
  }
}
