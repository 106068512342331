import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { DataCheckService } from '../services/data-check.service';

export const protectGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const appService = inject(AppService);
  const dataCheckService = inject(DataCheckService);

  const userData = appService.getUserData();
  const isSuperUser = dataCheckService.isSuperUser();

  if (userData?.access) {
    const routePath = route.url?.[0]?.path;

    // Super User access control
    if (isSuperUser) {
      if (routePath === 'gtadmin' || !route.url?.length) {
        setTimeout(() => {
          const statePaths = state.url.split('/')?.filter((item: any) => item);
          const alreadyInAdminRoute = statePaths?.length
            ? statePaths[0] === 'gtadmin'
            : false;

          // set time out added to avoid infinite redirecitons
          if (!route.url?.length && !alreadyInAdminRoute) {
            router.navigate(['/gtadmin/dashboard']);
          }
        }, 500);

        return true;
      } else {
        router.navigate(['404']);
        return false;
      }
    }
    // Normal User access control
    else {
      if (routePath === 'gtadmin') {
        router.navigate(['404']);
        return false;
      } else {
        return true;
      }
    }
  } else {
    router.navigate(['./login']);
    return false;
  }
};
